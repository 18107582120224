import './application.css';

import 'htmx.org';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { hits, configure } from 'instantsearch.js/es/widgets';
import { connectSearchBox } from 'instantsearch.js/es/connectors';

Alpine.plugin(intersect);

// init htmx
window.htmx = require('htmx.org');

// init alpine
window.Alpine = Alpine;

Alpine.start();

// Search component
//
// note any styles used here may need to be added to tailwind config if they're not used elsewhere, as they may be
// removed when tailwind optimised the final css build
document.addEventListener('DOMContentLoaded', function () {
    let domNode = document.querySelector('#primary-search-results');
    if (domNode == null) {
        return; // blank layout doesn't have generic search box
    }
    const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

    const search = instantsearch({
        indexName: ALGOLIA_INDEX_NAME,
        searchClient,
    });

    var renderSearchBox = function(renderOptions, isFirstRender) {
        // TODO

        var query = renderOptions.query;
        var refine = renderOptions.refine;
        var clear = renderOptions.clear;
        var isSearchStalled = renderOptions.isSearchStalled;
        var widgetParams = renderOptions.widgetParams;

        var input = document.querySelector('#primary-search-input');

        if (isFirstRender) {
            input.addEventListener('input', event => {
                refine(event.target.value);
            });
        }
    }

    var customSearchBox = connectSearchBox(renderSearchBox);

    search.addWidgets([
        customSearchBox({}),
        configure({hitsPerPage: 10}),
        hits({
            container: '#primary-search-results',
            templates: {
                item(hit, { html, components }) {
                    var classes = "block px-4 py-2 text-sm hover:bg-slate-100 hover:text-gray-700";
                    if (!hit.active) {
                        classes = "block px-4 py-2 text-sm hover:text-gray-700 text-gray-400 bg-gray-50";
                    }
                    return html`<a
                            href="/s/${hit.slug}"
                            class="${classes}"
                            role="menuitem"
                          >
                            ${components.Highlight({ hit, attribute: 'name' })}
                          </a>`;
                },
                empty: `<p class="block px-4 py-2 text-sm text-gray-500">We couldn't find any stocks for {{ query }} - did you try the full name?</p>`
            }
        }),
    ]);

    search.start();
});

var searchDefaultCache = null;
/**
 * Onboarding component
 */
document.addEventListener('DOMContentLoaded', function () {
    if (document.querySelector('#onboarding-search-results') == null) {
        // onboarding component not present
        return;
    }
    const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

    const search = instantsearch({
        indexName: ALGOLIA_INDEX_NAME,
        searchClient,
        searchFunction(helper) {
            var container = document.querySelector('#onboarding-search-results');
            if (helper.state.query == '') {
                if (searchDefaultCache == null) {
                    searchDefaultCache = '';
                    var defaultStocks = [
                        {name: 'NVIDIA CORP', slug: 'nvidia'},
                        {name: 'Walmart Inc.', slug: 'walmart'},
                        {name: 'Snowflake Inc.', slug: 'snowflake'},
                        {name: 'Chewy, Inc.', slug: 'chewy'}
                    ];
                    defaultStocks.forEach(function(stock) {
                        var slug = stock['slug'];
                        var name = stock['name'];
                        searchDefaultCache += '<button ' +
                            'class="inline-block mr-2 py-1 px-2 my-1 rounded border border-slate-950 bg-slate-50 hover:bg-emerald-400 text-slate-950 hover:text-white text-xs font-medium tracking-widest"' +
                            'hx-patch="/onboarding_sessions"' +
                            'hx-trigger="click"' +
                            'hx-target="#picked-stocks"' +
                            'hx-vals=\'{"slug": "' + slug + '"}\'>' +
                            '<form><input type="hidden" name="stock[slug]" value="' + slug + '"></form>' +
                            name + '</button>';
                    });
                }
                container.innerHTML = searchDefaultCache;
                htmx.process(container);
            } else {
                container.textContent = '';
                helper.search();
            }
        }
    });

    var renderSearchBox = function (renderOptions, isFirstRender) {
        // TODO

        var query = renderOptions.query;
        var refine = renderOptions.refine;
        var clear = renderOptions.clear;
        var isSearchStalled = renderOptions.isSearchStalled;
        var widgetParams = renderOptions.widgetParams;

        var input = document.querySelector('#onboarding-search');

        if (isFirstRender) {
            input.addEventListener('input', event => {
                refine(event.target.value);
            });
        }
    }

    var customSearchBox = connectSearchBox(renderSearchBox);

    search.addWidgets([
        customSearchBox({}),
        configure({hitsPerPage: 5}),
        hits({
            container: '#onboarding-search-results',
            templates: {
                item: `<button
                            class="inline-block mr-2 py-1 px-2 my-1 rounded border border-slate-950 bg-slate-50 hover:bg-emerald-400 text-slate-950 hover:text-white text-xs font-medium tracking-widest"
                            hx-patch="/onboarding_sessions"
                            hx-trigger="click"
                            hx-target="#picked-stocks"
                            hx-vals='{"slug": "{{ slug }}"}'
                          >
                            <form><input type="hidden" name="stock[slug]" value="{{ slug }}"></form>
                            {{#helpers.highlight}}{ "attribute": "name" }{{/helpers.highlight}}
                          </button>`,
                empty: `<p class="block p-2 rounded text-sm bg-yellow-100 text-yellow-800">We couldn't find any stocks for <span class="font-bold">{{ query }}</span></p>`
            },
            cssClasses: {
                item: ['inline-block']
            }
        }),
    ]);

    search.start();
});

/*
 *
 */
document.addEventListener('DOMContentLoaded', function() {
    if (document.querySelector('#onboarding-search-results') == null) {
        // onboarding component not present
        return;
    }
    const observer = new MutationObserver(function() {
        htmx.process(document.querySelector('#onboarding-search-results'));
    });

    observer.observe(document.querySelector('#onboarding-search-results'), { attributes: false, childList: true, characterData: false, subtree: true})
});